import { delay } from 'redux-saga'
import {
  all, takeLatest,
  put,
} from 'redux-saga/effects'
import { push } from 'react-router-redux';
import { connectionReady } from './actions'

export default function* sagas() {
  yield all([
    takeLatest('CHANGE_CONNECTION_STATUS', sendReady),
    takeLatest('RDP_CREDENTIALS_ERROR', handleRDPCredentialError),
  ])
}

function* handleRDPCredentialError({ payload: { detail } }) {
  if (detail === "Time's up!") {
    yield put(push('../passed/'))
  } else if (detail === 'All instances are busy.') {
    yield put(push('../busy/'))
  }
}

function* sendReady({ payload: { status } }) {
  if (status === 'connected') {
    yield delay(3 * 1000)
    yield put(connectionReady())
  } else if (status === 'other-connection-open') {
    yield put(push('../concurrent/'))
  }
}

export {
  handleRDPCredentialError,
  sendReady,
}
