import React, { Component } from 'react';
import Raven from 'raven-js';
import RavenComponent from 'react-raven';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { TranslationProvider } from 'react-admin3';
import { MuiThemeProvider } from '@material-ui/core/styles'

import './index.css';
// eslint-disable-next-line import/named
import store from '../store';
import Routes from '../pages/routes';
import history from '../history';
import theme from './theme'

const RAVEN_CONFIG = {
  tags: {
    git_commit: process.env.REACT_APP_COMMITHASH,
  },
  release: process.env.REACT_APP_COMMITHASH,
  sanitizeKeys: [/token/i, /password/i, /key/i, /username/i, /image/i],
  autoBreadcrumbs: {
    xhr: false, // XMLHttpRequest
    console: false, // console logging
    dom: false, // DOM interactions, i.e. clicks/typing
    location: false, // url changes, including pushState/popState
    sentry: false, // sentry events
  },
  dataCallback: (data) => {
    const { request = {} } = data
    return { ...data, request: { ...request, url: '' } }
  },
};

class App extends Component {
  componentDidCatch(error) {
    Raven.captureException(error.message)
  }

  render() {
    return (
      <div className="App">
        <RavenComponent
          dsn={process.env.REACT_APP_RAVEN_DSN}
          config={RAVEN_CONFIG}
        />
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <TranslationProvider>
              <ConnectedRouter history={history}>
                <Routes />
              </ConnectedRouter>
            </TranslationProvider>
          </Provider>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
