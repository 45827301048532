// @flow
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { adminReducer, i18nReducer } from 'react-admin3';
import englishMessages from 'ra-language-english';
import {
  createAppReducer,
  USER_LOGOUT,
  defaultI18nProvider as i18nProvider,
} from 'ra-core';
import student from '../../pages/student/reducers';
import rdpConnection from '../../pages/rdp/reducers';


const reducers = {
  admin: adminReducer,
  i18n: i18nReducer('en', englishMessages),
  form: formReducer,
  routing: routerReducer,
  student,
  rdpConnection,
};
export type Reducers = typeof reducers;


const locale = 'en';
const messages = i18nProvider(locale);
const appReducer = createAppReducer(reducers, locale, messages);

const resettableAppReducer = (state: any, action: any) => (
  appReducer(action.type !== USER_LOGOUT ? state : undefined, action)
);

export default resettableAppReducer
