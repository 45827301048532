import { put, all, takeLatest } from 'redux-saga/effects'
import { push } from 'react-router-redux';
import { sesssionComplete } from '../../rdp/actions'

function* watch() {
  yield all([
    takeLatest('CENTRIFUGE_MESSAGE', watchTestCompletion),
    takeLatest('CENTRIFUGE_MESSAGE', watchConcurrentSessions),
  ])
}

export default watch

function* watchTestCompletion(message) {
  const { payload: { data: { data } } } = message
  if (data === 'Test is over') {
    yield put(sesssionComplete())
    yield put(push('/student/complete/'))
  }
}

function* watchConcurrentSessions(message) {
  const { payload: { data } } = message
  if (data === 'concurrent') {
    yield put(push('/student/concurrent/'))
  }
}
