// @flow
import PropTypes from 'prop-types'
import withContext from 'recompose/withContext'
import {
  AUTH_LOGOUT,
  AUTH_LOGIN,
  AUTH_CHECK,
  AUTH_ERROR,
} from 'react-admin3'


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || ''
type AuthActionType = AUTH_LOGOUT | AUTH_LOGIN | AUTH_CHECK | AUTH_ERROR

const mapping = {
  [AUTH_LOGIN]: async ({ username, password }) => {
    const request = new Request(`${BACKEND_URL}/api/rest-auth/login/`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    const response = await fetch(request)
    await handleError(response)
    const { key } = await response.json()
    localStorage.setItem('token', key)
  },
  [AUTH_CHECK]: async () => {
    if (!localStorage.getItem('token')) {
      throw new Error('Unauthorized')
    }
  },
  [AUTH_LOGOUT]: async () => {
    const request = new Request(`${BACKEND_URL}/api/rest-auth/logout/`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Token ${getToken()}`,
        'Content-Type': 'application/json',
      }),
    })
    await fetch(request)
    localStorage.removeItem('token')
  },
  [AUTH_ERROR]: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      throw new Error('Unauthorized')
    }
  },
}
const handleError = async (response: Response) => {
  if (response.status === 400) {
    const { non_field_errors: detailError = [] } = await response.json()
    const errors = detailError.join(' ') || 'An error occured'
    throw new Error(errors)
  }
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText)
  }
}
export const authProvider = (type: AuthActionType, params: any) => {
  const handler = mapping[type]
  if (handler) {
    return handler(params)
  }
  return Promise.resolve()
}

export const getToken = () => localStorage.getItem('token') || ''

export default withContext({
  authProvider: PropTypes.func,
}, () => ({ authProvider }))
