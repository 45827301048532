// @flow
import { createFormAction } from 'redux-form-saga';

const postRequest = {
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
  },
}

export const GET_AUTHENTICATION_CODE = 'STUDENT/GET_AUTHENTICATION_CODE';
export const getAuthenticationCode = (
  username: string,
  courseID: string,
  moduleCode: string,
) => ({
  type: GET_AUTHENTICATION_CODE,
  request: {
    ...postRequest,
    url: '/api/auth/student/',
    body: JSON.stringify({
      username,
      course_id: courseID,
      module_code: moduleCode,
    }),
  },
});


export const form = 'student-authentication'
export const login = createFormAction(form);

export const AUTHENTICATE_STUDENT = 'STUDENT/AUTHENTICATE';
export const confirmAuthentication = (
  username: string,
  courseID: string,
  moduleCode: string,
  code: string,
) => ({
  type: AUTHENTICATE_STUDENT,
  request: {
    ...postRequest,
    url: '/api/auth/confirm/',
    body: JSON.stringify({
      username,
      code,
      course_id: courseID,
      module_code: moduleCode,
    }),
  },
});
