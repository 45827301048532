import { requestsReducer } from 'redux-saga-requests';
import {
  GET_AUTHENTICATION_CODE,
  AUTHENTICATE_STUDENT,
} from './actions';

export const authenticationCode = requestsReducer({
  actionType: GET_AUTHENTICATION_CODE,
});

export const authenticate = requestsReducer({
  actionType: AUTHENTICATE_STUDENT,
});
