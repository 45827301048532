// @flow

import type { Reducers } from './reducers';

type $ExtractFunctionReturn = <V>(v: (...args: any) => V) => V;

export type State = $ObjMap<Reducers, $ExtractFunctionReturn>;

/* eslint-disable global-require */
if (process.env.NODE_ENV === 'production') {
  module.exports = require('./index.prod');
} else {
  module.exports = require('./index.dev');
}
/* eslint-enable */
