import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#FF7900',
    },
    secondary: {
      main: '#000000',
    },
  },
  colors: {
    success: '#009e0f',
    pending: '#2b78e4',
    error: '#cf2a27',
    warning: '#ffea00',
  },
  typography: {
    fontFamily: '"Calibri", sans-serif',
  },
});
