import { createRequestInstance, watchRequests } from 'redux-saga-requests'
import Raven from 'raven-js'
import { createDriver as createFetchDriver } from 'redux-saga-requests-fetch'
import formActionSaga from 'redux-form-saga';

import { all, fork } from 'redux-saga/effects'
import adminSaga from '../../pages/trainer/sagas'
import studentSagas from '../../pages/student/sagas'
import rdpSagas from '../../pages/rdp/sagas'


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

function onErrorSaga(error, action) {
  const { status } = error
  if (status !== 401) {
    console.error(error, action)
    Raven.captureException(error.message)
  }
  return { error }
}

export default function* root() {
  yield createRequestInstance({
    driver: createFetchDriver(
      window.fetch,
      {
        baseURL: BACKEND_URL,
        AbortController: window.AbortController,
      },
    ),
    onError: onErrorSaga,
  })
  yield all([
    fork(formActionSaga),
    watchRequests(),
    fork(adminSaga),
    fork(studentSagas),
    fork(rdpSagas),
  ])
}
