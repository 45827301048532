// @flow
import { createActions } from 'redux-actions'
import type { ConnectionStatus } from '../../Domain'

export const {
  newConnectionAttempt,
  changeConnectionStatus,
  connectionReady,
  startConnecting,
  sesssionComplete,
} = createActions({
  NEW_CONNECTION_ATTEMPT: () => ({}),
  CHANGE_CONNECTION_STATUS: (status: ConnectionStatus) => ({ status }),
  CONNECTION_READY: () => ({ status: 'ready' }),
  SESSSION_COMPLETE: () => ({ status: 'completed' }),
  START_CONNECTING: (
    ip: string, terminateAt: number, durationSeconds: number,
  ) => ({ ip, terminateAt, durationSeconds }),
})
